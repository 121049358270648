/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css';
import { ChakraProvider } from '@chakra-ui/react';
import { App } from 'app/Loadable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'sanitize.css/sanitize.css';
import './locales/i18n';
import { ToastContainer } from 'react-toastify';
import * as serviceWorker from 'serviceWorker';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

const helmetContext = {};

const ConnectedApp = ({ Component }: Props) => (
  <HelmetProvider context={helmetContext}>
    <React.StrictMode>
      <ChakraProvider>
        <Component />
        <ToastContainer />
      </ChakraProvider>
    </React.StrictMode>
  </HelmetProvider>
);
const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV !== 'production') {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
